import { createSelector } from '@reduxjs/toolkit';
import type { RootState } from 'src/store';
import { TokenMapApi } from 'src/store/tokenMap/actions';
import type { GetTokensMapResponse } from 'src/store/tokenMap/type';

export const selectTokenMapData = createSelector(
  (state: RootState) => TokenMapApi.endpoints.getTokenMap.select()(state),
  (tokenMapResult) => {
    if (tokenMapResult?.data) {
      return tokenMapResult.data.data.reduce(
        (acc: Record<string, string>, item: GetTokensMapResponse) => {
          acc[item.symbol] = item.label;
          return acc;
        },
        {},
      );
    }
    return {};
  },
);
