import { combineReducers, configureStore } from '@reduxjs/toolkit';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { listenerMiddleware } from 'src/store/middleware/listenerMiddleware';
import { handleWsMessageMiddleware } from 'src/store/middleware/handleWsMessage';
import { sentryReduxEnhancer } from 'src/store/sentry-enhancers';
import { rtkAuthApi } from 'src/store/shared/rtkAuthApi';
import { rtkQueryErrorLogger } from 'src/store/middleware/rtkQueryLogger';
import { rtkApiKey } from 'src/store/apiKeys/actions';
import { rtkColdWalletApi } from 'src/store/cold-wallets/rtkColdWalletsApi';
import { rtkTokenMapApi } from './tokenMap/rtkTokenMapApi';
import loggerMiddleware from './middleware/logger';
import crashMiddleware from './middleware/crash';
import wsLoansMiddleware from './ws-loans/middleware';
import handleWsRequestMiddleware from './middleware/handleWsRequest';

import themeReducer from './theme/slice';
import adminReducer from './admin/slice';
import preferencesReducer from './preferences/slice';
import authReducer from './auth/slice';
import loansReducer from './loans/slice';

import wsLoansReducer from './ws-loans/slice';
import reportsReducer from './reports/slice';
import signupReducer from './signup/slice';
import rtkQueryErrorReducer from './error/slice';

import arbitrageReducer from './arbitrage/slice';
import apiKeysReducer from './apiKeys/slice';
import retainersReducer from './retainers/slice';

import accountsReducer from './shared/accounts/slice';
import exchangesReducer from './shared/exchanges/slice';
import balancesReducer from './shared/balances/slice';
import balanceHistoryReducer from './shared/balance-history/slice';
import transactionsReducer from './shared/transactions/slice';
import tradingReducer from './shared/trading/slice';
import arbitrageInfoReducer from './shared/arbitrage-info/slice';
import tasksReducer from './shared/tasks/slice';

export const rootPersistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: [], // things to persist
  // blacklist: [] // things to do not persist
};

export const authPersistConfig = {
  key: 'auth',
  version: 1,
  storage,
  blacklist: ['error', 'resAuth', 'res2FA', 'authDomain'],
};

export const prefPersistConfig = {
  key: 'preferences',
  version: 1,
  storage,
  blacklist: ['error'],
};

const reducers = combineReducers({
  [rtkTokenMapApi.reducerPath]: rtkTokenMapApi.reducer,
  [rtkAuthApi.reducerPath]: rtkAuthApi.reducer,
  [rtkColdWalletApi.reducerPath]: rtkColdWalletApi.reducer,
  [rtkApiKey.reducerPath]: rtkApiKey.reducer,
  wsLoans: wsLoansReducer,
  theme: themeReducer,
  auth: persistReducer(authPersistConfig, authReducer),
  preferences: persistReducer(prefPersistConfig, preferencesReducer),
  signup: signupReducer,
  rtkQueryError: rtkQueryErrorReducer,

  // shared
  accounts: accountsReducer,
  exchanges: exchangesReducer,
  balances: balancesReducer,
  balanceHistory: balanceHistoryReducer,
  transactions: transactionsReducer,
  trading: tradingReducer,
  arbitrageInfo: arbitrageInfoReducer,

  tasks: tasksReducer,

  // admin reducers
  admin: adminReducer,
  arbitrage: arbitrageReducer,
  apiKeys: apiKeysReducer,
  // loans reducers
  loans: loansReducer,

  reports: reportsReducer,

  // retainers reducers
  retainers: retainersReducer,
});

const persistedReducer = persistReducer(rootPersistConfig, reducers);

const extraArgument: { persistor?: any } = {};

export function setupStore(preloadedState?: any) {
  return configureStore({
    reducer: persistedReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
          // ignoredActions: ['LOANS_WS::OPEN'],
          ignoredActionPaths: ['meta.arg', 'meta.timestamp', 'payload', 'meta.baseQueryMeta'],
        },
        thunk: {
          extraArgument,
        },
      }).concat([
        // order matters
        rtkTokenMapApi.middleware,
        rtkAuthApi.middleware,
        rtkColdWalletApi.middleware,
        rtkApiKey.middleware,
        crashMiddleware,
        loggerMiddleware,
        wsLoansMiddleware,
        handleWsRequestMiddleware,
        handleWsMessageMiddleware,
        // loan and retainer middleware
        listenerMiddleware.middleware,
        rtkQueryErrorLogger,
      ]),
    enhancers: (getDefaultEnhancers) => getDefaultEnhancers().concat(sentryReduxEnhancer),
  });
}

export const store = setupStore();

export const persistor = persistStore(store);
extraArgument.persistor = persistor;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

export type AppStore = ReturnType<typeof setupStore>;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
