import type { GenericError } from 'src/store/utils/errors';
import type { RequestStatus } from 'src/store/types';
import type { JsonExchange } from 'src/store/shared/exchanges/types';
import type { LiquidityMap, LiquidityTuple } from 'src/store/loans/types';
import type { JsonKpi } from 'src/store/shared/kpi/types';
import type { IsoDateString } from 'src/utils/formatting/dates';

export enum LoanExchangesCommands {
  LoanExchanges = 'LoanExchanges',
}

// TODO: should it be a map?

export type AverageSpreadMap = Record<string, number>;

export interface LiquidityItem {
  Date: string;
  Ask: number;
  Bid: number;
  Depth: LiquidityTuple;
}

// [key: YYYY-MM-DDTHH:mm:ss string]: number
export type TradingVolumesMap = Record<string, number>;

export interface TradingVolumeItem {
  AccountID: string;
  Name: string;
  Date: IsoDateString;
  Ticker: string;
  Volume: number;
}

export interface MarketVolumesItem {
  Date: IsoDateString;
  ExchangeID: number;
  Volume: number;
}

export type ActualDepth = '1' | '2' | '5' | '10' | '-1' | '-2' | '-5' | '-10';

export interface JsonLoanExchange {
  exchangeID: number;
  pair: string;
  bid: number;
  ask: number;
  tradingVolumes: TradingVolumeItem[];
  // averageSpread: AverageSpreadMap;
  averageSpread: number; // was changed to number as we've receiving a aggregated spread here, that doesn't have a history
  ourSpread: number;
  marketLiquidity: LiquidityMap;
  ourLiquidity: LiquidityMap;
  marketVolumes: MarketVolumesItem[];
  actualLiquidity: Record<ActualDepth, number>;
}

export type JsonLoanExchangeExtended = JsonLoanExchange & {
  exchange: JsonExchange;
  kpi: (JsonKpi | null)[];
  tradingVolumeLast: number;
  tradingVolumePrev: number;
  tradingVolumeDiff: number;
  tradingVolumeByDate: [string, number][];
  marketVolume: number;
  marketVolumeByDate: [string, number][];
  volumeShare: number;
  volumeShareByDate: [string, number][];

  avgProjectLiquidityPlus1: number;
  avgProjectLiquidityPlus2: number;
  avgProjectLiquidityMinus1: number;
  avgProjectLiquidityMinus2: number;

  avgProjectLiquidityPlus1ByDate: [string, number][];
  avgProjectLiquidityPlus2ByDate: [string, number][];
  avgProjectLiquidityMinus1ByDate: [string, number][];
  avgProjectLiquidityMinus2ByDate: [string, number][];

  avgMarketLiquidityPlus1: number;
  avgMarketLiquidityMinus1: number;
  avgMarketLiquidityPlus2: number;
  avgMarketLiquidityMinus2: number;

  avgMarketLiquidityPlus1ByDate: [string, number][];
  avgMarketLiquidityPlus2ByDate: [string, number][];
  avgMarketLiquidityMinus1ByDate: [string, number][];
  avgMarketLiquidityMinus2ByDate: [string, number][];

  currentProjectLiquidityPlus1: number;
  currentProjectLiquidityPlus2: number;
  currentProjectLiquidityMinus1: number;
  currentProjectLiquidityMinus2: number;

  currentMarketLiquidityPlus1: number;
  currentMarketLiquidityPlus2: number;
  currentMarketLiquidityMinus1: number;
  currentMarketLiquidityMinus2: number;

  actualLiquidityPlus1: number;
  actualLiquidityPlus2: number;
  actualLiquidityMinus1: number;
  actualLiquidityMinus2: number;
};

export interface JsonLoanExchangesSuccess {
  command: LoanExchangesCommands.LoanExchanges;
  exchanges: JsonLoanExchange[];
  pairs: string[];
  supportedExchanges: number[];
}

export type LoanExchangesCache = Record<number, JsonLoanExchange[]>;

export interface LoanExchangesState {
  requestStatus: RequestStatus;
  error: null | GenericError;

  list: JsonLoanExchange[];
  supportedPairs: string[];
  supportedExchanges: number[];
  listCache: LoanExchangesCache;
}
