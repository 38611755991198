import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { actionOnMessage } from 'src/store/ws-loans/actions';
import { parseResponse } from 'src/store/utils';

import { TaskCommands } from 'src/store/shared/tasks/actions';
import { listen as listenLoan } from 'src/store/loans/actions';
import { listen as listenRetainer } from 'src/store/retainers/actions';
import type { JsonTasksSuccess, TasksState } from './types';

const allowedCommands = Object.values(TaskCommands);

const initialState: TasksState = {
  error: null,
  mm: [],
  icebergs: [],
  adaptives: [],
  liquid: [],
  dla: [],
  tiered: [],
  requestStatus: null,

  selectedStatus: [],
  sortKey: 'exchange:asc',
  searchValue: '',
};

const Tasks = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    resetFilters(state) {
      state.selectedStatus = [];
      state.sortKey = 'exchange:asc';
      state.searchValue = '';
    },
    setSearchValue(state, action: PayloadAction<string>) {
      state.searchValue = action.payload;
    },
    setSortKey(state, action: PayloadAction<string>) {
      state.sortKey = action.payload;
    },
    setSelectedStatus(state, action: PayloadAction<string[]>) {
      state.selectedStatus = action.payload;
    },

    resetTasks(state) {
      state.mm = [];
      state.icebergs = [];
      state.adaptives = [];
      state.liquid = [];
      state.dla = [];
      state.tiered = [];
    },
  },
  extraReducers: (builder) => {
    // on WS message

    builder.addCase(listenLoan.pending, (state, action: any) => {
      const { isInitCall, section } = action.meta.arg;
      if (isInitCall && section === 'bots') {
        state.requestStatus = 'pending';
      }
    });

    builder.addCase(listenRetainer.pending, (state, action: any) => {
      const { isInitCall, section } = action.meta.arg;
      if (isInitCall && section === 'bots') {
        state.requestStatus = 'pending';
      }
    });

    builder.addCase(actionOnMessage, (state, action: any) => {
      const { json, skipProcessing, error } = parseResponse(action.payload, allowedCommands);

      if (skipProcessing || !json) return;

      console.debug(
        `shared/tasks/processing ${actionOnMessage.toString()}`,
        action,
        allowedCommands,
      );

      if (error) {
        state.error = error;
        return;
      }

      state.error = null;

      const { command } = json;

      if (command === TaskCommands.Bots) {
        const { adaptives, icebergs, liquid, dla, mm, tiered }: JsonTasksSuccess = json;

        state.adaptives = adaptives;
        state.icebergs = icebergs;
        state.liquid = liquid;
        state.dla = dla;
        state.mm = mm;
        state.tiered = tiered;
        state.requestStatus = 'success';
      }
    });
  },
});

export const tasksActions = {
  ...Tasks.actions,
};
// export const { toggleSidebar, openNewLoanModal, closeNewLoanModal } = Balances.actions

export default Tasks.reducer;
