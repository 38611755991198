import { createSlice } from '@reduxjs/toolkit';

import { createWsAction, parseResponse } from 'src/store/utils';
import { actionOnMessage } from 'src/store/ws-loans/actions';
import type { SliceFactoryProps } from 'src/store/shared/types';
import isFunction from 'lodash/isFunction';
import type { RequestStatus } from 'src/store/types';
import type { FormKpi, JsonKpi, JsonLoanKpiSuccess, KpiState, PayloadKpi } from './types';
import { KpiCommands } from './types';
import { registerErrorMessage } from 'src/store/utils/errors';
import type { RetainerKpiCommands } from 'src/store/retainers/kpi/slice';
import type { LoanKpiCommands } from 'src/store/loans/kpi/slice';

function initKpiStateFactory(opts?: Partial<KpiState>) {
  const initialState: KpiState = {
    error: null,
    requestStatus: null,
    formKpi: null,
    kpiList: null,
    selectedEditKpi: null,
    resAddKpi: null,
    resEditKpi: null,
    resRemoveKpi: null,
  };
  return { ...initialState, ...opts };
}

registerErrorMessage(KpiCommands.AddKPI, { title: 'Failed to add KPI' });
registerErrorMessage(KpiCommands.EditKPI, { title: 'Failed to update KPI' });
registerErrorMessage(KpiCommands.RemoveKPI, { title: 'Failed to remove KPI' });

export function initKpiSlice({
  prefix = 'shared',
  sliceName = 'kpi',
  initialState = initKpiStateFactory(),
  extraReducers,
  commands,
}: SliceFactoryProps<
  KpiState,
  typeof KpiCommands & (typeof RetainerKpiCommands | typeof LoanKpiCommands)
>) {
  if (!commands) {
    throw new Error('Commands are required for KPI slice factory');
  }

  const allowedCommands = Object.values(commands);

  const addKpi = createWsAction<PayloadKpi, PayloadKpi>(`${prefix}/addKpi`, (data: PayloadKpi) => ({
    command: commands.AddKPI,
    ...data,
  }));

  const editKpi = createWsAction<PayloadKpi, PayloadKpi>(
    `${prefix}/editKpi`,
    (data: PayloadKpi) => ({
      command: commands.EditKPI,
      ...data,
    }),
  );

  const removeKpi = createWsAction<number, { id: number }>(`${prefix}/removeKpi`, (id) => ({
    command: commands.RemoveKPI,
    id,
  }));

  const kpiSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
      setSelectedEditKpi(state, action: { payload: JsonKpi | null }) {
        state.selectedEditKpi = action.payload;
      },

      setFormKpi(state, action: { payload: FormKpi[] }) {
        state.formKpi = action.payload;
      },

      setKpiRequestStatus(state, action: { payload: RequestStatus }) {
        state.requestStatus = action.payload;
      },
    },
    extraReducers: (builder) => {
      // listen
      if (isFunction(extraReducers)) {
        extraReducers(builder);
      }

      builder.addCase(addKpi, (state) => {
        state.resAddKpi = 'pending';
      });

      builder.addCase(editKpi, (state) => {
        state.resEditKpi = 'pending';
      });

      builder.addCase(removeKpi, (state) => {
        state.resRemoveKpi = 'pending';
      });

      // on WS message
      builder.addCase(actionOnMessage, (state, action: any) => {
        const { json, skipProcessing, error, errorCommand } = parseResponse(
          action.payload,
          allowedCommands,
        );

        if (skipProcessing || !json) return;

        console.debug(
          `${prefix}/processing ${actionOnMessage.toString()}`,
          action,
          allowedCommands,
        );

        if (error) {
          state.error = error;

          switch (errorCommand) {
            case commands.AddKPI:
              state.resAddKpi = 'error';
              break;
            case commands.EditKPI:
              state.resEditKpi = 'error';
              break;
            case commands.RemoveKPI:
              state.resRemoveKpi = 'error';
              break;
            default:
          }
        }

        const { command, okCommand } = json;

        if (command === 'ok' && okCommand === commands.AddKPI) {
          state.formKpi = null;
          // state.resUpdateLoan = 'success';
          state.resAddKpi = 'success';
        }
        if (command === 'ok' && okCommand === commands.EditKPI) {
          state.resEditKpi = 'success';
          state.selectedEditKpi = null;
        }

        if (command === 'ok' && okCommand === commands.RemoveKPI) {
          state.resRemoveKpi = 'success';
          return;
        }

        if (command === commands.KPI) {
          const { kpi } = json as JsonLoanKpiSuccess;
          state.kpiList = kpi;
        }
      });
    },
  });

  const actions = {
    ...kpiSlice.actions,
    addKpi,
    editKpi,
    removeKpi,
  };

  return {
    actions,
    reducer: kpiSlice.reducer,
  };
}
