import flatMap from 'lodash/flatMap';
import map from 'lodash/map';
import reject from 'lodash/reject';

import type { JsonConnection } from 'src/store/types';
import type { JsonAccountStats } from 'src/store/shared/accounts/types';

// convert JsonAccountStats to JsonConnection

export const prepareAccounts = (accounts: JsonAccountStats[]): JsonConnection[] =>
  flatMap(accounts, (item, idx) =>
    map(
      item.accounts,
      (account) =>
        ({
          name: account.id,
          label: account.name,
          status: item.status ?? '',
          message: item.error ?? '',
          proxy: '',
          inviteLink: account.inviteLink ?? '',
          group: idx + 1,
          exchange: item.exchange,
          botType: account.botType || undefined,
          ticker: account.ticker || undefined,
        }) as JsonConnection,
    ),
  );

const sortFunction = (a: any, b: any, sortKey: string) => {
  switch (sortKey) {
    case 'exchange:asc':
      return b.name - a.name;
    case 'exchange:desc':
      return a.name - b.name;
    default:
      return 0;
  }
};

const filterFunction = (item: JsonConnection, searchValueDefault: string) => {
  const parts = searchValueDefault.toLowerCase().split(' ');
  const itemString = `${item.name} ${item.label} `.toLowerCase();
  return parts.every((token) => itemString.includes(token));
};

export const getAccountsFilteredSortedData = (
  accounts: JsonConnection[],
  sortKey: string,
  searchValue: string,
) => {
  const filterData = reject(accounts || [], (pair) => {
    if (searchValue.length > 2 && !filterFunction(pair, searchValue)) {
      return true;
    }

    return false;
  });

  return filterData.toSorted((a, b) => sortFunction(a, b, sortKey));
};
