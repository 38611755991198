import { createSelector } from '@reduxjs/toolkit';

import {
  getExtendedBalancesHistory,
  getFilteredTokens,
  getLinearData,
  getNewExtendedBalances,
  getTransactionSummary,
  getUniqueBalanceTokens,
  getUniqueCompareTokens,
} from 'src/store/utils/balances';
import {
  selectBalanceHistory,
  selectComparisonEndDate,
  selectComparisonStartDate,
  // selectPriceHistory,
} from 'src/store/shared/balance-history/selectors';
import { selectExchanges } from 'src/store/shared/exchanges/selectors';

import {
  selectBalancesList,
  selectBalancesSelection,
  selectBalancesSupportedTokens,
} from 'src/store/shared/balances/selectors';
import { selectJoinedAccounts } from 'src/store/shared/accounts/selectors';
import { selectTransactions } from 'src/store/shared/transactions/selectors';

import {
  selectIgnoreTokens,
  selectIsDuplicatesVisible,
  selectIsIgnoreOtherTokens,
  selectIsInitialBalanceUsed,
  selectIsSmallBalancesVisible,
  selectShowDlaBots,
  selectViewChartGroupBy,
  selectViewCumulativeBalance,
} from 'src/store/loans/balance-table-opts/selectors';
import { selectTokenMapData } from 'src/store/shared/tokenMap/selectors';
import { selectColdWallets, selectWalletsSnapshots } from 'src/store/loans/cold-wallets/selectors';
import type { JsonConnection } from 'src/store/types';
import type {
  JsonBalance,
  JsonBalanceExtended,
  JsonBalanceHistoryExtended,
} from 'src/store/shared/balances/types';
import type { JsonExchange } from 'src/store/shared/exchanges/types';
import type {
  JsonBalanceHistoryItem,
  UniqueTokensList,
} from 'src/store/shared/balance-history/types';
import type { JsonTransaction } from 'src/store/shared/transactions/types';
import { selectViewTarget } from 'src/store/loans/balance-table-opts/selectors';
import { ApplicationDomain } from 'src/sky-router';
// import type { WalletData } from 'src/store/cold-wallets/types';

export const selectLoanBalancesTokens = createSelector(
  selectBalancesList,
  selectColdWallets,
  selectIsIgnoreOtherTokens,
  selectBalancesSupportedTokens,
  selectTokenMapData,
  (
    balances: JsonBalance[] | undefined = [],
    coldWallets: JsonBalance[] | undefined = [],
    isIgnoreOtherTokens: boolean | undefined,
    supportedTokens: UniqueTokensList[] | undefined = [],
    tokenMap: Record<string, string> | undefined,
  ) =>
    getUniqueBalanceTokens({
      data: [...balances, ...coldWallets],
      isIgnoreOtherTokens,
      supportedTokens,
      tokenMap,
    }),
);

const selectUniqToken = createSelector(
  selectLoanBalancesTokens,
  selectIgnoreTokens,
  selectIsSmallBalancesVisible,
  (tokens, ignoreTokens, isSmallBalancesVisible) =>
    getFilteredTokens({ tokens, ignoreTokens, isSmallBalancesVisible }),
);

// balances list with exchange and account details populated

export const selectLoanBalancesExtended = createSelector(
  selectIsSmallBalancesVisible,
  selectIsDuplicatesVisible,
  selectBalancesList,
  selectColdWallets,
  selectExchanges,
  selectJoinedAccounts,
  selectShowDlaBots,
  selectUniqToken,
  selectTokenMapData,
  (
    isSmallBalancesVisible: boolean | undefined,
    isDuplicatesVisible: boolean | undefined,
    balances: JsonBalance[] | undefined,
    coldWallets: JsonBalance[] | undefined,
    exchanges: JsonExchange[],
    allAccounts: JsonConnection[],
    showDlaBots: boolean | undefined,
    selectedTokens: UniqueTokensList[],
    tokenMap: Record<string, string> | undefined,
  ): JsonBalanceExtended[] =>
    getNewExtendedBalances({
      domain: ApplicationDomain.Loans,
      isSmallBalancesVisible,
      isDuplicatesVisible,
      balances,
      coldWallets,
      exchanges,
      accounts: allAccounts,
      showDlaBots,
      selectedTokens,
      tokenMap,
    }),
);

export const selectLoanBalancesHistoryExtended = createSelector(
  selectIsSmallBalancesVisible,
  selectIsDuplicatesVisible,
  selectIsInitialBalanceUsed,
  selectComparisonStartDate,
  selectComparisonEndDate,
  selectBalancesList,
  selectBalanceHistory,
  selectColdWallets,
  selectWalletsSnapshots,
  selectExchanges,
  selectJoinedAccounts,
  selectTransactions,
  selectShowDlaBots,
  selectBalancesSupportedTokens,
  selectTokenMapData,
  (
    isSmallBalancesVisible: boolean | undefined,
    isDuplicatesVisible: boolean | undefined,
    isInitialBalanceUsed: boolean | undefined,
    comparisonStartDate: string | undefined,
    comparisonEndDate: string | undefined,
    balances: JsonBalance[] | undefined,
    history: JsonBalanceHistoryItem[] | undefined,
    coldWallets: JsonBalance[] | undefined,
    coldWalletsSnapshots: JsonBalanceHistoryItem[] | undefined,
    exchanges: JsonExchange[],
    allAccounts: JsonConnection[],
    transactions: JsonTransaction[],
    showDlaBots: boolean | undefined,
    supportedTokens: Omit<UniqueTokensList, 'isSmallBalance'>[] | undefined = [],
    tokenMap: Record<string, string> | undefined,
  ): JsonBalanceHistoryExtended[] =>
    getExtendedBalancesHistory({
      domain: ApplicationDomain.Loans,
      isSmallBalancesVisible,
      isDuplicatesVisible,
      isInitialBalanceUsed,
      comparisonStartDate,
      comparisonEndDate,
      balances,
      history,
      coldWallets,
      coldWalletsSnapshots,
      exchanges,
      accounts: allAccounts,
      transactions,
      showDlaBots,
      supportedTokens,
      tokenMap,
      isUseTransitionsDiff: false,
    }),
);

export const selectLinearData = createSelector(
  selectLoanBalancesHistoryExtended,
  selectBalancesSelection,
  selectComparisonStartDate,
  selectComparisonEndDate,
  selectViewTarget,
  selectViewChartGroupBy,
  selectIgnoreTokens,
  selectViewCumulativeBalance,
  (
    data,
    selection,
    startDate,
    endDate,
    viewTarget,
    viewChartGroupBy,
    ignoreTokens,
    viewCumulativeBalance,
  ) =>
    getLinearData({
      data,
      selection,
      viewTarget,
      viewChartGroupBy,
      ignoreTokens,
      viewCumulativeBalance,
      startDate,
      endDate,
    }),
);

export const selectLoanCompareTokens = createSelector(
  selectLoanBalancesHistoryExtended,
  selectIsIgnoreOtherTokens,
  selectTokenMapData,
  (
    history: JsonBalanceHistoryExtended[] | undefined,
    isIgnoreOther: boolean,
    tokenMap: Record<string, string> | undefined,
  ) =>
    getUniqueCompareTokens({
      data: history,
      isIgnoreOther,
      tokenMap,
    }),
);

export const selectLoanBalancesHistoryExtendedUseTransactionForDiff = createSelector(
  selectIsSmallBalancesVisible,
  selectIsDuplicatesVisible,
  selectIsInitialBalanceUsed,
  selectComparisonStartDate,
  selectComparisonEndDate,
  selectBalancesList,
  selectBalanceHistory,
  selectColdWallets,
  selectWalletsSnapshots,
  selectExchanges,
  selectJoinedAccounts,
  selectShowDlaBots,
  selectBalancesSupportedTokens,
  selectTokenMapData,
  selectTransactions,
  (
    isSmallBalancesVisible: boolean | undefined,
    isDuplicatesVisible: boolean | undefined,
    isInitialBalanceUsed: boolean | undefined,
    comparisonStartDate: string | undefined,
    comparisonEndDate: string | undefined,
    balances: JsonBalance[] | undefined,
    history: JsonBalanceHistoryItem[] | undefined,
    coldWallets: JsonBalance[] | undefined,
    coldWalletsSnapshots: JsonBalanceHistoryItem[] | undefined,
    exchanges: JsonExchange[],
    allAccounts: JsonConnection[],
    showDlaBots: boolean | undefined,
    supportedTokens: Omit<UniqueTokensList, 'isSmallBalance'>[] | undefined = [],
    tokenMap: Record<string, string> | undefined,
    transactions: JsonTransaction[],
  ): JsonBalanceHistoryExtended[] =>
    getExtendedBalancesHistory({
      domain: ApplicationDomain.Loans,
      isSmallBalancesVisible,
      isDuplicatesVisible,
      isInitialBalanceUsed,
      comparisonStartDate,
      comparisonEndDate,
      balances,
      history,
      coldWallets,
      coldWalletsSnapshots,
      exchanges,
      accounts: allAccounts,
      showDlaBots,
      transactions,
      supportedTokens,
      tokenMap,
      isUseTransitionsDiff: true,
    }),
);

export const selectLoanTransactionSummary = createSelector(
  selectTransactions,
  selectComparisonStartDate,
  selectComparisonEndDate,
  getTransactionSummary,
);
