import { createSelector } from '@reduxjs/toolkit';

import sortBy from 'lodash/sortBy';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import first from 'lodash/first';

import { selectExchanges } from 'src/store/shared/exchanges/selectors';
import type { JsonExchange } from 'src/store/shared/exchanges/types';
import type {
  AssetInfo,
  JsonRetainersExchange,
  JsonRetainersExchangeExtended,
  JsonRetainersPiechartData,
  JsonRetainersStats,
  RetainersSunburstData,
} from 'src/store/retainers/types';
import { getCurrentLiquidityByDepth, getLiquidityDetailsByDepth } from 'src/store/utils/liquidity';
import map from 'lodash/map';
import sumBy from 'lodash/sumBy';
import { getTradingVolumeData } from 'src/store/loans/exchanges/selectors';
import type { JsonBalance } from 'src/store/shared/balances/types';
import reduce from 'lodash/reduce';
import groupBy from 'lodash/groupBy';
import mapValues from 'lodash/mapValues';
import values from 'lodash/values';
import { getPieColor } from 'src/store/utils/color';
import type { RootState } from 'src/store';
import type { AutoCompleteItem } from 'src/store/types';
import type { JsonProjectAdmin } from './admin-panel/slice';

export const selectError = (store: RootState) =>
  store?.retainers?.projects?.error ?? store?.retainers?.adminPanel?.error;

export const selectIsListenSent = (store: RootState) =>
  store.retainers.index.listenStatus === 'success';

export const selectProjects = (store: RootState) => store.retainers.projects.list;
export const selectAccountsAdmin = (store: RootState) => store.retainers.adminPanel.userAccounts;
export const selectProjectsAdmin = (store: RootState) => store.retainers.adminPanel.projects;
export const selectProjectsAdminLoading = (store: RootState) =>
  store.retainers.adminPanel.requestStatus === 'pending';
export const selectIsSidebarOpen = (store: RootState) => store.retainers.index.isSidebarOpen;
export const selectTargetProject = (store: RootState) => store.retainers.index.targetProject;
export const selectTargetExchange = (store: RootState) => store.retainers.index.targetExchange;
export const selectRetainersExchanges = (store: RootState) => store.retainers.index.exchanges;
export const selectRetainersBalances = (store: RootState) => store.retainers.index.balances;
export const selectIsRetainersExchangeLoading = (store: RootState) =>
  store.retainers.index.listenStatus === 'pending';
export const selectOverviewActiveTab = (store: RootState) =>
  store.retainers.index.overviewActiveTab;

export const selectCurrentProject = createSelector(
  selectTargetProject,
  selectProjectsAdmin,
  (targetProject: string, items: JsonProjectAdmin[] | null) => {
    if (items === null || !targetProject) {
      return undefined;
    }

    return find(items, { id: +targetProject }) ?? null;
  },
);

export const selectDefaultProject = createSelector(
  selectProjectsAdmin,
  (items: JsonProjectAdmin[] | null) => first(sortBy(items, 'id')) ?? null,
);

export const selectCurrentExchange = createSelector(
  selectTargetExchange,
  selectExchanges,
  (targetExchange: string, items: JsonExchange[]) => {
    const currentExchange: JsonExchange | undefined = targetExchange
      ? (find(items, { id: +targetExchange }) ?? undefined)
      : undefined;
    return currentExchange;
  },
);

export const selectNextProject = createSelector(
  selectTargetProject,
  selectProjectsAdmin,
  (targetProject: string, items: JsonProjectAdmin[] | null) => {
    const sortedItems = sortBy(items, 'id');
    let nextIdx = findIndex(sortedItems, { id: +targetProject }) + 1;
    if (nextIdx === Number(targetProject)) {
      nextIdx += 1;
    }
    const nextProject: JsonProjectAdmin | undefined =
      (nextIdx < sortedItems.length && sortedItems[nextIdx]) || undefined;
    return nextProject;
  },
);

export const selectPrevProject = createSelector(
  selectTargetProject,
  selectProjectsAdmin,
  (targetProject: string, items: JsonProjectAdmin[] | null) => {
    const sortedItems = sortBy(items, 'id');
    const prevIdx = findIndex(sortedItems, { id: +targetProject }) - 1;
    const prevProject: JsonProjectAdmin | undefined =
      (prevIdx >= 0 && sortedItems[prevIdx]) || undefined;
    return prevProject;
  },
);

const sumStables = (assets: any) =>
  reduce(assets, (sum, asset) => sum + asset.volume * asset.lastPrice, 0);

export const selectRetainersOverview = createSelector(
  selectRetainersExchanges,
  selectRetainersBalances,
  selectExchanges,
  (
    retainerExchanges: JsonRetainersExchange[] | null,
    balances: JsonBalance[] | null,
    exchanges: JsonExchange[] | null,
  ) => {
    if (!retainerExchanges && !balances) {
      return [] as JsonRetainersExchangeExtended[];
    }

    return map(retainerExchanges, (item) => {
      const { exchangeID, pair } = item;
      const [baseToken] = pair.split('/');

      const exchange = find(exchanges, { id: exchangeID });

      const relevantBalances = balances?.filter((balance) => balance.exchangeID === exchangeID);

      const baseAssets = relevantBalances?.flatMap((balance) =>
        balance.assets.filter((asset) => asset.token === baseToken && asset.type === 1),
      );

      const otherAssets = relevantBalances?.flatMap((balance) =>
        balance.assets.filter((asset) => asset.type === 2 || asset.type === 0),
      );

      const groupedOtherAssets = groupBy(otherAssets, 'token');
      const mergedOtherAssets = map(groupedOtherAssets, (assets, token) => ({
        token,
        volume: sumBy(assets, 'volume'),
        lastPrice: assets[0].lastPrice,
      }));

      const stables = sumStables(otherAssets);
      const baseVolume = baseAssets?.reduce((sum, asset) => sum + asset.volume, 0);
      const baseLastPrice = baseAssets?.length ? baseAssets[baseAssets.length - 1].lastPrice : 0;

      const otherTokens = mergedOtherAssets?.map((asset) => ({
        token: asset.token,
        volume: asset.volume,
        lastPrice: asset.lastPrice,
      }));

      const { tradingVolumeLast } = getTradingVolumeData(item.tradingVolumes);

      const marketVolume = sumBy(item.marketVolumes, 'Volume') || 0;
      const volumeShare = (tradingVolumeLast / marketVolume) * 100;

      const currentLiquidityPlus2 = getCurrentLiquidityByDepth({
        liquidity: item.ourLiquidity,
        depth: 2,
      });
      const currentLiquidityPlus5 = getCurrentLiquidityByDepth({
        liquidity: item.ourLiquidity,
        depth: 5,
      });
      const currentLiquidityPlus10 = getCurrentLiquidityByDepth({
        liquidity: item.ourLiquidity,
        depth: 10,
      });

      const currentLiquidityMinus2 = getCurrentLiquidityByDepth({
        liquidity: item.ourLiquidity,
        depth: -2,
      });
      const currentLiquidityMinus5 = getCurrentLiquidityByDepth({
        liquidity: item.ourLiquidity,
        depth: -5,
      });
      const currentLiquidityMinus10 = getCurrentLiquidityByDepth({
        liquidity: item.ourLiquidity,
        depth: -10,
      });

      const avgLiquidityPlus2ByDate = getLiquidityDetailsByDepth({
        liquidity: item.ourLiquidity,
        depth: +2,
      });
      const avgLiquidityPlus5ByDate = getLiquidityDetailsByDepth({
        liquidity: item.ourLiquidity,
        depth: +5,
      });
      const avgLiquidityPlus10ByDate = getLiquidityDetailsByDepth({
        liquidity: item.ourLiquidity,
        depth: +10,
      });

      const avgLiquidityMinus2ByDate = getLiquidityDetailsByDepth({
        liquidity: item.ourLiquidity,
        depth: -2,
      });
      const avgLiquidityMinus5ByDate = getLiquidityDetailsByDepth({
        liquidity: item.ourLiquidity,
        depth: -5,
      });
      const avgLiquidityMinus10ByDate = getLiquidityDetailsByDepth({
        liquidity: item.ourLiquidity,
        depth: -10,
      });

      return {
        ...item,

        currentLiquidityPlus2,
        currentLiquidityPlus5,
        currentLiquidityPlus10,
        currentLiquidityMinus2,
        currentLiquidityMinus5,
        currentLiquidityMinus10,

        avgLiquidityPlus2ByDate,
        avgLiquidityPlus5ByDate,
        avgLiquidityPlus10ByDate,
        avgLiquidityMinus2ByDate,
        avgLiquidityMinus5ByDate,
        avgLiquidityMinus10ByDate,

        volumeShare,

        baseToken: { token: baseToken, volume: baseVolume, lastPrice: baseLastPrice },

        otherTokens,
        stables,
        exchange,
      } as JsonRetainersExchangeExtended;
    });
  },
);

export const selectRetainersStats = createSelector(
  selectRetainersOverview,
  (overviewData: JsonRetainersExchangeExtended[]) => {
    if (!overviewData.length) {
      return {} as JsonRetainersStats;
    }

    const groupedData = groupBy(overviewData, 'exchangeID');

    const aggregatedData = reduce(
      groupedData,
      (acc, exchange) => {
        const uniqueBaseTokens = new Set<string>();
        const uniqueOtherTokens = new Set<string>();

        exchange.forEach((item) => {
          if (!uniqueBaseTokens.has(item.baseToken.token)) {
            uniqueBaseTokens.add(item.baseToken.token);
            acc.baseTokenTotal += item.baseToken.volume * item.baseToken.lastPrice;
            acc.baseTokenTotalVolume += item.baseToken.volume;
          }

          item.otherTokens.forEach((token) => {
            if (!uniqueOtherTokens.has(token.token)) {
              uniqueOtherTokens.add(token.token);
              acc.otherTokensTotal += token.volume * token.lastPrice;
            }
          });

          if (item.lastPrice !== 0) {
            acc.lastPriceTotal += item.lastPrice;
            acc.lastPriceCount += 1;
          }
          acc.baseToken = item.baseToken.token;
        });
        return acc;
      },
      {
        baseTokenTotal: 0,
        baseTokenTotalVolume: 0,
        otherTokensTotal: 0,
        lastPriceTotal: 0,
        lastPriceCount: 0,
        baseToken: '',
      },
    );

    return {
      ...aggregatedData,
    };
  },
);

export const selectRetainersPiechartData = createSelector(
  selectRetainersOverview,
  selectRetainersStats,
  (overviewData: JsonRetainersExchangeExtended[], retainersStats) => {
    if (!overviewData.length && !retainersStats) {
      return [];
    }

    const groupedData = groupBy(overviewData, 'exchangeID');

    const aggregatedData = mapValues(groupedData, (items) => {
      const aggregatedItem = reduce(
        items,
        (acc, item, i) => {
          acc.value +=
            item.baseToken.volume * item.baseToken.lastPrice +
            sumBy(item.otherTokens, (token) => token.volume * token.lastPrice);
          acc.id = item.exchange.name;
          acc.label = item.exchange.name;
          acc.color = getPieColor(i);
          return acc;
        },
        {
          value: 0,
          percentageValue: 0,
          id: '',
          label: '',
          color: '',
        },
      );

      aggregatedItem.percentageValue =
        aggregatedItem.value / (retainersStats.baseTokenTotal + retainersStats.otherTokensTotal);
      return aggregatedItem;
    });

    return values(aggregatedData) as JsonRetainersPiechartData[];
  },
);

export const selectRetainersSunburstData = createSelector(
  selectRetainersOverview,
  (overviewData): RetainersSunburstData[] | undefined => {
    if (!overviewData.length) {
      return undefined;
    }

    const groupedData = groupBy(overviewData, 'exchangeID');
    return map(groupedData, (exchangeItems) =>
      reduce(
        exchangeItems,
        (acc, item) => {
          acc.baseToken.token = item.baseToken.token;
          acc.baseToken.volume += item.baseToken.volume;
          acc.baseToken.lastPrice = item.baseToken.lastPrice;

          item.otherTokens.forEach((token) => {
            const existingToken = acc.otherTokens.find((t) => t.token === token.token);
            if (existingToken) {
              existingToken.volume += token.volume;
              existingToken.lastPrice = token.lastPrice;
            } else {
              acc.otherTokens.push({ ...token });
            }
          });

          acc.exchange = item.exchange.name;

          acc.id = `${item.exchange.name}_${item.baseToken.token}`;
          return acc;
        },
        {
          baseToken: { token: '', volume: 0, lastPrice: 0 } as AssetInfo,
          otherTokens: [] as AssetInfo[],
          id: '',
          exchange: '',
        },
      ),
    );
  },
);

export const selectLinechartRange = (store: RootState) => store.retainers.index.linechartRange;
export const selectLinechartStacked = (store: RootState) => store.retainers.index.linechartStacked;

const filterAndSortProjects = (
  projects: JsonProjectAdmin[] | null,
  sortKey: string,
  searchValue: string,
) => {
  if (!projects) return [];

  let filteredData = projects;

  if (searchValue.length >= 2) {
    const parts = searchValue.toLowerCase().split(' ');

    filteredData = projects.filter((item) => {
      const itemString = `${item.name}`.toLowerCase();
      return parts.every((token) => itemString.includes(token));
    });
  }

  return filteredData.slice().sort((a, b) => {
    switch (sortKey) {
      case 'name_asc':
        return a.name.localeCompare(b.name);
      case 'name_desc':
        return b.name.localeCompare(a.name);
      case 'created_asc':
        return a.id - b.id;
      case 'created_desc':
        return b.id - a.id;
      default:
        return 0;
    }
  });
};

export const selectSortKey = (state: any) => state.retainers.projects.sortKey;
export const selectSearchInput = (state: any) => state.retainers.projects.searchInput;

export const selectSortedProjects = createSelector(
  [selectProjectsAdmin, selectSortKey, selectSearchInput],
  (projects, sortKey, searchValue) => filterAndSortProjects(projects, sortKey, searchValue),
);

export const selectRetainerCompanies = () => [];

export const selectRetainerCompanyOptions = createSelector(
  selectRetainerCompanies,
  (items: string[]) => {
    const companyOptions: AutoCompleteItem[] = items.map((company: string) => ({
      key: company,
      value: company,
      label: company,
    }));

    return companyOptions;
  },
);

export const selectIsNewProjectModalOpen = (store: RootState) =>
  store.retainers.index.isNewProjectModalOpen;
